import React, { useEffect } from "react";
import { configureAmplify } from "@thatsclutch/shared";
import { isLoggedIn } from "../components/Auth/AppUser";
import { navigate } from "gatsby";

configureAmplify(process.env.GATSBY_AMP_ENV);

const App = () => {
  const user = isLoggedIn();

  useEffect(() => {
    if (user && user.userGroups.includes("admins")) navigate(`/app/admin`);
    if (user && user.userGroups.includes("creators"))
      navigate("/app/dashboard");
    else navigate("/app/signin");
  }, []);

  return null;
};

export default App;
